import {useRef, useState, useEffect, useCallback} from "react";
import {
    Typography,
    Box,
    IconButton,
    ListItem,
    ListItemText,
    Checkbox,
    List,
    Collapse,
    CircularProgress,
    SvgIcon,
    Fade,
    Alert,
    Link
} from "@mui/material";
import {
    StyledAreaTextField,
    StyledPrefixTextField,
    StyledListItemIcon,
    StyledHoverListItemButton,
} from "../../../styles/mui_styles";
import {TransitionGroup} from 'react-transition-group';
import MemoizedListItem from "./MemoizedListItem";
import {
    useSearchPhoneNumbersMutation
} from "../../../services/requests";
import findConsecutives from "../../../helpers/findConsecutives";
import {DEFAULT_AREA_CODE, MAX_ALLOWED_QUANT} from "../../../constants/constants";

const ChooseNumbers = ({availableDids, setAvailableDids, selectedDids, setSelectedDids, poc}) => {
    const [areaCode, setAreaCode] = useState('');
    const [prefix, setPrefix] = useState('');
    const areaCodeRef = useRef(null);
    const prefixRef = useRef(null);

    const lastDIDRef = useRef(null);
    const [exceedsMaxQuant, setExceedsMaxQuant] = useState(false);

    const [searchPhoneNumbersRequest, searchPhoneNumbersResponse] = useSearchPhoneNumbersMutation();

    const handleAreaCodePrefixChange = (e, type) => {
        searchPhoneNumbersResponse.reset();
        let sanitizedInput = e.target.value.replace(/[^0-9]+/g, '').slice(0, 3);
        if (type === "areaCode") {
            setAreaCode(sanitizedInput);
            if (sanitizedInput.length === 3) {
                prefixRef.current.focus();
            }
        }
        if (type === "prefix") {
            setPrefix(sanitizedInput);
            if (sanitizedInput.length === 0) {
                areaCodeRef.current.focus();
            }
        }
    }

    const handleSearch = ({areaCode, prefix}) => {
        setAvailableDids([]);
        const code = areaCode ? areaCode.toString() : DEFAULT_AREA_CODE;
        searchPhoneNumbersRequest({code: code + prefix.toString(), quantity: 5000});
    }

    useEffect(() => {
        if (searchPhoneNumbersResponse.isSuccess && searchPhoneNumbersResponse.data.length) {
            let phone_numbers = [];
            const values = findConsecutives([...searchPhoneNumbersResponse.data], "100");
            values.forEach(item => {
                item.forEach(number => {
                    if (phone_numbers.length < 100) {
                        phone_numbers.push({
                            number,
                            checked: selectedDids.find(item => item.number == number) ? true : false,
                            resource: false
                        });
                    }
                })
            })
            setAvailableDids(phone_numbers);
        }
    }, [searchPhoneNumbersResponse?.isSuccess])

    const handleDIDSelection = (value, bucket) => {
        if (poc && bucket != "selected" && !value.checked && selectedDids.length == MAX_ALLOWED_QUANT) {
            setExceedsMaxQuant(true);
            setTimeout(() => {
                setExceedsMaxQuant(false);
            }, 4000);
            return;
        }

        setAvailableDids(prev => {
            const prevCopy = JSON.parse((JSON.stringify(prev)));
            const number = prevCopy.find(item => item.number == value.number);
            if (number) {
                number.checked = !number.checked;
            }
            return prevCopy;
        })

        setSelectedDids((prev) => {
            const prevCopy = JSON.parse((JSON.stringify(prev)));
            if (!value.checked) {
                prevCopy.push({...value, checked: true});
            } else {
                const numberIndex = prevCopy.findIndex(item => item.number == value.number);
                prevCopy.splice(numberIndex, 1);
            }
            return prevCopy;
        })
        if (!value.checked) {
            setTimeout(() => {
                console.log("lastDIDRef.current", lastDIDRef.current)
                lastDIDRef.current.scrollIntoView({block: "end", behavior: 'smooth'});
            }, 600)
        }
    }

    return (
        <>
            <Box className="step_content">
                <Box className="form_title">
                    <Typography component="span" className="form_title--step-1">
                        Search for phone numbers by area code and or prefix.
                        {poc && <span>{" "} Select up to {MAX_ALLOWED_QUANT} phone numbers. If you need more, please {" "}
                            <Link target="_blank" href="mailto:sales@bluip.com" className="form_title--subtitle_link">
                        contact our Sales team.
                    </Link>
                    </span>
                        }
                    </Typography>
                </Box>

                <Box className="form_block">
                    <StyledAreaTextField
                        autoComplete='off'
                        onChange={(e) => handleAreaCodePrefixChange(e, "areaCode")}
                        value={areaCode}
                        size="small"
                        variant="filled"
                        label="Area Code"
                        inputRef={areaCodeRef}
                        error={searchPhoneNumbersResponse.isError}
                        helperText={searchPhoneNumbersResponse.isError && "Please enter a valid input."}
                    />
                    <StyledPrefixTextField
                        autoComplete='off'
                        onChange={(e) => handleAreaCodePrefixChange(e, "prefix")}
                        value={prefix}
                        size="small"
                        variant="filled"
                        label="Prefix"
                        inputRef={prefixRef}
                        error={searchPhoneNumbersResponse.isError}
                    />
                    <IconButton
                        className="form_block--search"
                        onClick={() => handleSearch({areaCode, prefix})}
                        disabled={searchPhoneNumbersResponse.isLoading}
                    >
                        {
                            searchPhoneNumbersResponse.isLoading ?
                                <CircularProgress className="form_block--loader" style={{height: 20, width: 20}}/> :
                                <SvgIcon className="search_icon"/>
                        }
                    </IconButton>
                </Box>
                <Box className="number_block">
                    <Box className="number_block--available">
                        <Typography className="number_block--title">Available {availableDids.length}</Typography>
                        <List className="number_block--list_block">
                            {
                                //searchPhoneNumbersResponse.isSuccess && availableDids.length ?
                                availableDids.length ?
                                    availableDids.map(item => {
                                        return <MemoizedListItem {...item} key={item.number}
                                                                 handleDIDSelection={handleDIDSelection}/>
                                    }) :
                                    searchPhoneNumbersResponse.isSuccess && !searchPhoneNumbersResponse.data.length ?
                                        <Typography className="number_block--no-found">
                                            We could not find any phone numbers matching your search criteria. Please
                                            try searching for
                                            different area code and or prefix.
                                        </Typography> :
                                        null
                            }
                        </List>
                    </Box>
                    <Box className="number_block--claimed">
                        <Typography className="number_block--title">Claimed {selectedDids.length} {poc &&
                        <span>{" "} of {MAX_ALLOWED_QUANT}{" "}</span>}</Typography>
                        <TransitionGroup className="number_block--list_block">
                            {selectedDids?.map((item, index) => {
                                return <Collapse timeout={600} key={item.number}
                                                 ref={index === selectedDids.length - 1 ? lastDIDRef : null}>
                                    <ListItem component="div" disablePadding>
                                        <StyledHoverListItemButton role={undefined}
                                                                   onClick={() => handleDIDSelection(item, "selected")}
                                                                   dense>
                                            <StyledListItemIcon>
                                                <Checkbox
                                                    checkedIcon={<SvgIcon className='checked_icon'/>}
                                                    icon={<SvgIcon className='unchecked_icon'/>}
                                                    sx={{paddingTop: 0, paddingBottom: 0}}
                                                    size="small"
                                                    edge="start"
                                                    checked={true}
                                                    disableRipple
                                                />
                                            </StyledListItemIcon>
                                            <ListItemText className="number_block--num_text" primary={item.number}/>
                                        </StyledHoverListItemButton>
                                    </ListItem>
                                </Collapse>
                            })}
                        </TransitionGroup>
                        <Fade
                            in={exceedsMaxQuant}
                            appear={exceedsMaxQuant} timeout={600}>
                            <Typography className="number_block--claimed__error">
                                You can get max {MAX_ALLOWED_QUANT} phone numbers.
                            </Typography>
                        </Fade>
                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default ChooseNumbers
