export const MAX_ALLOWED_QUANT = 5;
export const DEFAULT_AREA_CODE = "747";
export const DEFAULT_COUNTRY = "USA";

export const TABLE_HEADERS = [
    {
        field: "telephoneNumber",
        name: "Phone Numbers"
    },
    {
        field: "isoCountryCode",
        name: "Country"
    },
    {
        field: "acquiredOn",
        name: "Acquired Date"
    },
    {
        field: "numberType",
        name: "Phone Number Type"
    }
]

export const FILTERS = {
    telephoneNumber: ["All"],
    isoCountryCode: ["All", "US", "Canada"],
    acquiredOn: ["Last 7 days", "Last 30 days", "Last 60 days"],
    numberType: ["All", "User", "Resource"]
}

export const POC_MENU_ITEMS = [
    {name: "Admin Center", link: "https://admin.teams.microsoft.com/"},
    {name: "Operator Connect", link: "https://www.bluip.com/microsoft-operator-connect"},
    {name: "Contact Us", link: "https://www.bluip.com/company/contact"},
    {name: "About BluIp", link: "https://www.bluip.com/company"}
]

export const CUSTOMER_MENU_ITEMS = [
    {name: "Number Management"},
]

export const POC_ROUTES = ["/start_trial", "/active_lead", "/lead_already_processed"]

export const CUSTOMER_ROUTES = ["/numbers"]